<template>
  <v-card
    :class="{
      'card--flex-toolbar-xs': $vuetify.breakpoint.xsOnly,
      'card--flex-toolbar': $vuetify.breakpoint.smAndUp
    }"
  >
    <v-toolbar flat dense class="elevation-3" color="secondary">
      <v-btn small fab dark color="info" @click="$router.go(-1)" class="mx-1">
        <v-icon dark>arrow_back_ios</v-icon>
      </v-btn>
      <v-toolbar-title class="body-2 white--text">{{
        this.$route.meta.subtitle
      }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn small fab dark class="mx-1" color="primary" @click="reload">
        <v-icon dark>autorenew</v-icon>
      </v-btn>
      <v-btn
        small
        fab
        dark
        class="mx-1"
        color="accent"
        :to="{
          path:
            '/unitedit/-1/' +
            (this.$route.params.parent + '/' + this.$route.params.pid)
        }"
      >
        <v-icon dark>add</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text :class="{ 'pa-0': $vuetify.breakpoint.xsOnly }">
      <v-container
        fluid
        fill-height
        class="pt-0"
        :class="{ 'pa-0': $vuetify.breakpoint.xsOnly }"
      >
        <v-row no-gutters align-space-around justify-center>
          <v-col cols="12">
            <InteractiveTable
              ref="unitList"
              class="lg-table"
              :headers="[
                { text: 'ID', value: 'id' },
                { text: 'Naziv', value: 'name', width: 250 },
                { text: 'Opis', value: 'description' },
                { text: 'Adresa', value: 'address' },
                { text: 'Grad', value: 'city' },
                { text: 'Email', value: 'email' }
              ]"
              apiLoad="unit/units.php"
              :actions="[
                {
                  id: '1',
                  name: 'Uredi organizacijsku jedinicu',
                  path: '/unitedit/#id#/unit',
                  icon: 'edit',
                  color: 'secondary'
                },
                {
                  id: '2',
                  name: 'Organizacijske jedinice niže razine',
                  path: '/unitlist/unit/#id#',
                  icon: 'folder_shared',
                  color: 'primary'
                },
                {
                  id: '3',
                  name: 'Članovi organizacijske jedinice',
                  path: '/userroleunitlist/unit/#id#',
                  icon: 'people',
                  color: 'accent'
                }
              ]"
              defaultFilter="name"
              :queryFilter="this.$route.params"
              defaultSort="name"
            ></InteractiveTable>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import InteractiveTable from "@/components/InteractiveTable.vue";

export default {
  name: "UnitList",
  data() {
    return {};
  },
  components: {
    InteractiveTable
  },
  computed: {
    needsRefresh: function() {
      return this.$store.getters.unitListNeedsRefresh;
    }
  },
  watch: {
    needsRefresh(needsRefresh) {
      if (needsRefresh) {
        this.reload();
      }
    }
  },
  methods: {
    reload() {
      this.$refs.unitList.reload();
      this.$store.dispatch("unit_list_refreshed");
    }
  }
};
</script>
